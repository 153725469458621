import React from "react";
import {graphql, useStaticQuery} from "gatsby";

import Layout from "../../components/layout";
import SEO from "../../components/seo";
import Talk from "../../components/talk";

import '../../components/index.scss';
import HomeLink from "../../components/home-link";

const AccessibilityInIosPage = () => {
  const imageQuery = useStaticQuery(graphql`
    query {
      cover: file(relativePath: { eq: "talks/accessibility-in-ios.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const title = "Accessibility in iOS";
  const summary = `After receiving a workshop in Apple's headquarters in Accessibility, this was a premier for the rest of
      the iOS team on basic Accessibility practices in iOS.`;

  return <Layout>
    <SEO title={title} description={summary}/>
    <HomeLink/>
    <Talk
      imageQuery={imageQuery}
      name={title}
      year={2017}
      slidesURL={"https://docs.google.com/presentation/d/1GXLrMqLECop7mvUVMoSlruPEcqjWF8w66Ys7TvdcnoY/edit?usp=sharing"}
      summary={summary}
    />
  </Layout>
};

export default AccessibilityInIosPage
